@import "./variables";
@import "./mixins";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&family=Space+Mono:ital@0;1&display=swap");

html,
body {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  // font-weight: 300;
}

h2 {
  font-family: "Space Mono", monospace;
  font-size: 16px;
  font-style: italic;

  @include tablet {
    font-size: 20px;
  }
}

h4 {
  font-family: "Space Mono", monospace;
  font-size: 12px;
  font-style: italic;

  @include tablet {
    font-size: 14px;
  }
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;

  @include tablet {
    font-size: 16px;
    line-height: 36px;
  }
}

a {
  // text-decoration: none;
  color: black;

  &:active {
    color: black
  }

  &:hover {
    color: $accent;
  }
}

.accent {
  color: $accent;
}
