@mixin tablet {
  @media (min-width:768px){
    @content;
  }
}

@mixin desktop {
  @media (min-width:1300px){
    @content;
  }
}

// @include tablet{
//   padding: 0 1.5rem 0;
// } 