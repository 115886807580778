@import "../../partials/mixins";
@import "../../partials/variables";

.main__projects {
  display: flex;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}

.project {
  width: 100%;
  height: 120vw;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  border: solid rgba(0, 0, 0, 0.1) 2px;
  // overflow: hidden;
  background: $project;

  @include tablet {
    width: 45%;
    max-width: 442.8px;
    height: 54.45vw;
    max-height: 544.5px;
  }

  &__hero {
    height: 71.4vw;
    width: 100%;
    background: $project;

    @include tablet {
      height: calc(71.4vw * 0.45);
      max-height: 321.3px;
    }
  }

  &__hero-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  &__hero-img {
    height: 100%;
    display: block;
    background: lightblue;
  }

  &__info-container {
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    // margin: 0.5rem 0;
  }

  &__title-container {
    width: 70%;
  }

  &__details p {
    // display: flex;
    line-height: 1.2rem;
  }

  &__details-icons {
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: center;
    padding: 0.5rem 0;
    // background: pink;
  }

  &__details-icons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
  }

  &__tech-icon {
    height: 30px;
    width: 30px;
  }

  &__details-icon-text {
    text-align: center;
  }

  &__details-date {
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: space-between;
    // background: lightcoral;
  }

  &__pitch {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
}
