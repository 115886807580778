@import "../../partials/mixins";

.project__bg {
  @include tablet {
    // padding: 1rem;
    margin: 0 auto 40px;
    // padding: 1rem;
    max-width: 1000px;
  }
}

.project-page {
  padding: 0.5rem;

  @include tablet {
    padding: 1.5rem;
  }
}

.project {
  &__details {
    padding: 0.5rem 0;
  }

  &__description{
    padding: 0 0 1rem;
  }
}
