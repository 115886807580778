@import "../../partials/mixins";
@import "../../partials/variables";

.nav {
  // background: $background;
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  z-index: 0;
  width: 100%;

  @include tablet {
    max-width: calc(1000px + 3rem);
    padding: 0 1.5rem;
  }

  &__btn {
    font-size: 40px;
    height: 80px;
    width: 80px;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include tablet {
      font-size: 50px;
    }
  }

  &__modal {
    background: lightblue;
    height: 300px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
    box-shadow: 0px 0px 20px 20px rgba(134, 169, 180, 0.1);
    border-radius: 10px;
  }

  &__overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background: lightblue;
    z-index: 901;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.2s;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
