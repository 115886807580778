@import "../../partials/mixins";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.gallery {
  max-height: 500px;
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;

  &__img {
    max-width: 100%;
    max-height: 400px;
    margin: auto;
    padding: 1rem 0;

    @include tablet {
      padding: 0 1rem;
    }
  }

  &__slider {
    width: calc(100% - 50px);
    height: 100%;
  }
}


