@import "../../partials/mixins";

.info {
  padding: 3.5rem 0;
  width: 100%;

  @include tablet {
    margin: auto;
    max-width: 1000px;
    padding: 1.5rem;
  }

  &__hello-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1rem;
  }

  &__hello {
  }

  &__title {
  }

  &__about-me {
    padding: 1rem 0;
  }

  &__head-shot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__head-shot {
    height: 100px;
    border-radius: 50%;
    transition: all 0.2s;

    @include tablet {
      height: 300px;
    }
  }
}
