@import "../../partials/mixins";

.main {
  padding: 0.5rem;

  @include tablet {
    max-width: 1000px;
    margin: auto;
  }

  &__projects-title {
    font-size: 24px;

    @include tablet {
      text-align: center;
    }
  }
}
