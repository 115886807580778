@import "./partials/fonts";
@import "./partials/variables";
@import './partials/mixins';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: $background;

  // @include tablet {
  //   background: white;
  // }
}

.cta {
  height: 48px;
  margin: 1.5rem 0;
  width: 100%;
  background-color: $accent;
  outline: none;
  border: none;
  color: white;
  font-family: "Space Mono", monospace;
  font-size: 1rem;
  border-radius: 4px;

  &:hover{
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  @include tablet {
    width: 140px;
    margin: 3rem 0;
  }
}

.goBack {
  display: flex;
  // justify-content: center;
  align-items: center;
  background: none;
  outline: none;
  border: none;
  position: sticky;
  top: 1rem;
  background: whitesmoke;
  box-shadow: 5px 5px 5px rgba(0,0,0,.2);
  border-radius: 4px;
  padding: .5rem;
  z-index: 100;
  margin-left: .5rem;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  @include tablet {
    margin-left: 1.5rem;
  }

  &__icon {
    height: 10px;
    margin-right: 4px;
  }
}
