@import "../../partials/mixins";

.header {
  padding: 0.5rem;
  height: 80px;

  @include tablet {
    padding: 0 1.5rem;
    max-width: 1000px;
    margin:auto;
  }
}

.typical {
  width: 90%;
  position: relative;
  z-index: 1;
}
